html {
    background: #fefefe; /* fallback for old browsers */
    min-height: 100%;
    height: 100%;
    padding-bottom: 100px;
}

.App {
    height: 100%;
}

.shadowFX {
    box-shadow: rgba(17, 12, 46, 0.25) 0px 48px 100px 0px;
}
